import { ApplicationConfig } from '@angular/core';
import {
  enableProdMode,
  ENVIRONMENT_INITIALIZER,
  importProvidersFrom,
  inject,
  LOCALE_ID,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import {
  ConnectionModule,
  CoreModule,
  Store,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import { environment } from '../environments/environment';
import {
  accountContextProviders,
  connectionSettingsProviders,
  settingsProviders,
  userContextProviders,
  utilitiesProviders,
} from '@softapps/allgemein/utils';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  AppearanceModule,
  ApplicationModule,
  ConsoleModule,
  DeveloperModule,
  ItemScanModule,
  OfflineModule,
} from '@softline/application';
import { DynamicModule } from '@softline/dynamic';
import {
  loginProviders,
  SOFTLINE_CONFIG_LOGIN,
} from '@softapps/allgemein/login';
import { passwordProviders } from '@softapps/allgemein/password';
import { moduleProviders } from '@softapps/allgemein/modules';
import { lagerCoreProviders } from '@softapps/lager/core';
import { palettenUmlagerungProviders } from '@softapps/lager/paletten-umlagerung';
import { lagerplatzUmlagerungProviders } from '@softapps/lager/lagerplatz-umlagerung';
import { lagerkorrekturProviders } from '@softapps/lager/lagerkorrektur';
import { inventurProviders } from '@softapps/lager/inventur';
import { kommissionierungProviders } from '@softapps/lager/kommissionierung';
import { lagerstandProviders } from '@softapps/lager/lagerstand';
import { lagerkorrekturArtikelProviders } from '@softapps/lager/lagerkorrektur-artikel';
import { platform } from '../platforms/platform';
import { default as de } from '../../i18n/de.json';
import { wwsCoreProviders } from "@softapps/wws/core";
import {SoftappsCoreModule} from '@softapps/allgemein/core';
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(environment.connection),
      AuthenticationModule.forRoot(),
      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      AppearanceModule.forRoot({
        defaultSettings: { theme: environment.theme },
      }),
      DynamicModule.forRoot(),
      DeveloperModule.forRoot(),
      ConsoleModule.forRoot(),
      OfflineModule.forRoot(),
      ItemScanModule.forRoot(),
      SoftappsCoreModule.forRoot(),
    ),

    //Allgemein
    ...utilitiesProviders,
    ...userContextProviders,
    ...settingsProviders,
    ...connectionSettingsProviders,
    ...loginProviders,
    ...passwordProviders,
    ...moduleProviders,

    //WWS
    ...wwsCoreProviders,

    //Lager
    ...lagerCoreProviders,
    ...palettenUmlagerungProviders,
    ...lagerplatzUmlagerungProviders,
    ...lagerkorrekturProviders,
    ...inventurProviders,
    ...kommissionierungProviders,
    ...lagerstandProviders,
    ...lagerkorrekturArtikelProviders,

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: SOFTLINE_CONFIG_LOGIN, useValue: { scan: true } },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => {
        const store = inject(Store);
        store.commit(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.mutations.add,
          {
            module: 'zentrallager',
            language: 'de',
            translations: de,
            priority: 1,
          }
        );
      },
      multi: true,
    },
  ],
};
